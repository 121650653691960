
#main {
  margin: 0 1rem;
}

@media (min-width: 1281px) {
  .service-item {
    max-width: 320px;
  }

  /* CSS */

  #main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content(1fr);
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "header"
      "cover"
      "hero"
      "services"
      "contact";
  }

  #header {
    grid-area: header;
  }

  #cover {
    grid-area: cover;
  }

  #hero {
    grid-area: hero;
  }

  #services {
    grid-area: services;
  }

  #contact {
    grid-area: contact;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .service-item {
    max-width: 320px;
  }

  /* CSS */

  #main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content(1fr);
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "header"
      "cover"
      "hero"
      "services"
      "contact";
  }

  #header {
    grid-area: header;
  }

  #cover {
    grid-area: cover;
  }

  #hero {
    grid-area: hero;
  }

  #services {
    grid-area: services;
  }

  #contact {
    grid-area: contact;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .service-item {
    max-width: 320px;
  }

  /* CSS */

  #main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content(1fr);
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "header"
      "cover"
      "hero"
      "services"
      "contact";
  }

  #header {
    grid-area: header;
  }

  #cover {
    grid-area: cover;
  }

  #hero {
    grid-area: hero;
  }

  #services {
    grid-area: services;
  }

  #contact {
    grid-area: contact;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */

  .service-item {
    max-width: 320px;
  }

  #main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content(1fr);
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "header"
      "cover"
      "hero"
      "services"
      "contact";
  }

  #header {
    grid-area: header;
  }

  #cover {
    grid-area: cover;
  }

  #hero {
    grid-area: hero;
  }

  #services {
    grid-area: services;
  }

  #contact {
    grid-area: contact;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */

  .service-item {
    max-width: 320px;
  }

  #main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content(1fr);
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "header"
      "cover"
      "hero"
      "services"
      "contact";
  }

  #header {
    grid-area: header;
  }

  #cover {
    grid-area: cover;
  }

  #hero {
    grid-area: hero;
  }

  #services {
    grid-area: services;
  }

  #contact {
    grid-area: contact;
  }

}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {

  .service-item {
    min-width: 320px;
  }

  /* CSS */
}